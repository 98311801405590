import "react-tooltip/dist/react-tooltip.css";
import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Home, AdminPage } from "./components/index";
import Auth from "./auth";
import { getalldata } from "./components/pages/admin-component/adminpanel/hooks/apis";
import { useDispatch } from "react-redux";

// uncommment it.
// import { PrivateRoute } from "./components/pages/private/privateroute";
// import PageNotFound from "./components/pages/private/page404";
import { Emulator } from "./components/pages/admin-component/emulatornewpureweb";
import { purewebContext } from "./context/PurewebContext";
import { useState } from "react";

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [imgbbContainerVisible, setImgbbContainerVisible] = useState(false);
  const [upload3DContainerVisible, setUpload3DContainerVisible] =
    useState(false);
  const [playfabData, setPlayfabData] = useState([]);
  const [currentEmitter, setCurrentEmitter] = useState(null);
  const [downloadVisible, setDownloadVisible] = useState(false);
  const [preparingDownload, setPreparingDownload] = useState(false);
  const [renderStarted, setRenderStarted] = useState(false);
  const [imagefileone, setImagefileone] = useState("");
  const [fullScreenTrigger, setFullScreenTrigger] = useState("noinit");
  const [refreshRequiredModalVisible, setRefreshRequiredModalVisible] =
    useState(false);
  const [lowConnectionMessageVisible, setLowConnectionMessageVisible] =
    useState(false);
  const [
    connectionSuccessLoadingApplication,
    setConnectionSuccessLoadingApplication,
  ] = useState(false);
  const [shownNetworkMessage, setShownNetworkMessage] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  // const [streamQuality, setStreamQuality] = useState(localStorage.getItem("streamQuality") || "fhd");
  const [streamQuality, setStreamQuality] = useState("fhd");

  // timers
  const [timeLeft, setTimeLeft] = useState(20);
  const timerIdRef = useRef(null);

  const startTimer = () => {
    if (timeLeft > 0) {
      timerIdRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (document.querySelector(".marker-app")) {
      if (location.pathname === "/render") {
        document.querySelector(".marker-app").style.display = "block";
      } else {
        document.querySelector(".marker-app").style.display = "none";
      }
    }
  }, [location]);

  useEffect(() => {
    if (timeLeft === 0) {
      setRefreshRequiredModalVisible(true);
    }
  }, [timeLeft]);

  const stopTimer = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = null;
  };

  useEffect(() => {
    dispatch({ type: "load_loader" });
    const exec = async () => {
      const response = await getalldata(dispatch);
      const executefun = async () => {
        await fetch("https://api.rendernow.co/");
      };
      executefun();
      if (response) {
        dispatch({ type: "unload_loader" });
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement("script"),
            s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = "https://embed.tawk.to/610eea1ed6e7610a49af1a2d/1fch4524j";
          s1.charset = "UTF-8";
          s1.setAttribute("crossorigin", "*");
          s0.parentNode.insertBefore(s1, s0);
        })();
      }
    };
    exec();
  }, []);

  useEffect(() => {
    localStorage.setItem("streamQuality", streamQuality);
  }, [streamQuality]);

  return (
    <div>
      <purewebContext.Provider
        value={{
          upload3DContainerVisible,
          setUpload3DContainerVisible,
          imgbbContainerVisible,
          setImgbbContainerVisible,
          currentEmitter,
          setCurrentEmitter,
          downloadVisible,
          setDownloadVisible,
          preparingDownload,
          setPreparingDownload,
          renderStarted,
          setRenderStarted,
          imagefileone,
          setImagefileone,
          fullScreenTrigger,
          setFullScreenTrigger,
          refreshRequiredModalVisible,
          setRefreshRequiredModalVisible,
          lowConnectionMessageVisible,
          setLowConnectionMessageVisible,
          shownNetworkMessage,
          setShownNetworkMessage,
          startTimer,
          stopTimer,
          connectionSuccessLoadingApplication,
          setConnectionSuccessLoadingApplication,
          userLoggedIn,
          setUserLoggedIn,
          streamQuality,
          setStreamQuality,
          setPlayfabData,
          playfabData,
        }}
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/auth" component={Auth} />
          {/* remove it */}
          <Route path="/dashboard" component={AdminPage} />
          <Route path="/render" component={Emulator} />
          {/* <Route path="/auth/login" component={Emulator} /> */}

          {/* uncomment it */}
          {/* <PrivateRoute path="/dashboard">
            <AdminPage />
          </PrivateRoute> */}
          {/* <Route
            exact
            path={localStorage.getItem("token") ? "/render" : "/no-data"}
            component={Emulator}
          />  
          <Route path="*" component={PageNotFound} /> */}
        </Switch>
      </purewebContext.Provider>
    </div>
  );
}
