import React from "react";
import { Button, Icon } from "semantic-ui-react";

import "./Launch.css";
import clientConfig from "./client.json";
const client = clientConfig;

export const LaunchView = (props) => {
  return (
    <div id="launchContainer">
      <div>
        <div
          onClick={() => props.Launch()}
          style={{
            color: "white",
            fontSize: 16,
            marginBottom: 15,
            letterSpacing: 0.6,
          }}
        >
          {client.description}
        </div>
        <Button
          size="massive"
          color="blue"
          circular
          icon="play"
          onClick={() => props.Launch()}
        ></Button>
      </div>
    </div>
  );
};
