import { useEffect, useRef } from "react";

const UploadWidget = ({ isOpen, onSuccess, onError }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current?.createUploadWidget?.(
      {
        cloudName: "daicbqtlb",
        uploadPreset: "imgbb_replace",
      },
      function (error, result) {
        if (error) {
          onError(error);
        } else {
          onSuccess(result);
        }
      }
    );
  }, []);

  useEffect(() => {
    console.log(`latest isOpen ${isOpen}`);
    if (isOpen) {
      widgetRef.current?.open?.();
    } else {
      widgetRef.current?.close?.();
    }
  }, [isOpen]);
};

export default UploadWidget;
