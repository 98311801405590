import React, { useState } from "react";
import { renderpageapi } from "./hooks/apis";
import { useSelector, useDispatch } from "react-redux";

const Rendernow = () => {
  const [link, setLink] = useState({
    links: "",
    sdklink: "",
    purewebProjectId: "",
    purewebModelId: "",
    purewebProjectIdGlobal: "",
    purewebModelIdGlobal: "",
    purewebProjectIdDev: "",
    purewebModelIdDev: "",
  });

  const response = useSelector((state) => state.adminReducer.render_message);
  const dispatch = useDispatch();

  const handlesubmit = (e) => {
    e.preventDefault();
    renderpageapi(link, dispatch);
    setLink("");
  };

  return (
    <section
      className="my-3"
      style={{
        borderBottom: "0.2rem solid rgb(25, 118, 210)",
        borderRadius: "8px",
      }}
    >
      {response === "show" && (
        <div
          className="alert alert-success mt-2 text-center font-weight-bold"
          role="alert"
        >
          Changes are successfully applied
        </div>
      )}
      {response === "no_show" && (
        <div className="alert alert-danger" role="alert">
          Some Error occured
        </div>
      )}

      <section className="row my-3 justify-content-end mx-1">
        <button
          type="submit"
          style={{ backgroundColor: "#1565c0", color: "white" }}
          className="my-2 py-2 px-3 rounded font-weight-bold shadow-lg oncea-button"
          onClick={handlesubmit}
        >
          Submit
        </button>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Furioos SDK Link
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.sdklink}
            onChange={(e) =>
              setLink((v) => ({ ...v, sdklink: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Pureweb Project ID - USA
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.purewebProjectId}
            onChange={(e) =>
              setLink((v) => ({ ...v, purewebProjectId: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Pureweb Model ID - USA
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.purewebModelId}
            onChange={(e) =>
              setLink((v) => ({ ...v, purewebModelId: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Pureweb Project ID - Global
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.purewebProjectIdGlobal}
            onChange={(e) =>
              setLink((v) => ({ ...v, purewebProjectIdGlobal: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Pureweb Model ID - Global
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.purewebModelIdGlobal}
            onChange={(e) =>
              setLink((v) => ({ ...v, purewebModelIdGlobal: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Pureweb Project ID - DEV
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.purewebProjectIdDev}
            onChange={(e) =>
              setLink((v) => ({ ...v, purewebProjectIdDev: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Pureweb Model ID - DEV
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <input
            type="text"
            name="monday.com"
            className="w-100 px-2 py-1"
            value={link.purewebModelIdDev}
            onChange={(e) =>
              setLink((v) => ({ ...v, purewebModelIdDev: e.target.value }))
            }
          />
        </form>
      </section>

      <section className="row my-3">
        <header
          className="col-lg col-12 text-left h5 bannerfont ml-3"
          style={{ fontWeight: 600 }}
        >
          Monday.com Form
        </header>
        <form className="col d-flex flex-column justify-content-left align-items-left shadow-md my-1 rounded">
          <textarea
            type="text"
            placeholder="Place Here Only Iframe Link"
            name="monday.com"
            className="w-100 px-2"
            rows="6"
            cols="30"
            value={link.links}
            onChange={(e) => setLink((v) => ({ ...v, links: e.target.value }))}
          />
        </form>
      </section>
    </section>
  );
};

export default Rendernow;
