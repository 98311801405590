import React, { useEffect, useState } from "react";
import { dashboardpageapi } from "./hooks/apis";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeLinksClickable } from "../dashboard";
// import { AiFillDelete } from "react-icons/ai";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  maxHeight: "90vh",
  overflow: "auto",
};

const Dashboard = () => {
  const [data, setData] = useState({
    welcome: "",
    welcome_text: "",
    youtube: "",
  });
  const [updatesData, setUpdatesData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [focusedUpdates, setFocusedUpdates] = useState(null);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    if (!open) {
      setFocusedUpdates(null);
      setModalData({
        title: "",
        description: "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (focusedUpdates) {
      const selectedUpdate = updatesData.find(
        (item) => item._id === focusedUpdates
      );
      setModalData({
        title: selectedUpdate.title || "",
        description: selectedUpdate.description || "",
      });
    }
  }, [focusedUpdates]);

  const fetchUpdates = async () => {
    try {
      const response = await axios.get("https://api.rendernow.co/updates");
      const { existingUpdates } = response.data;
      setUpdatesData(existingUpdates);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteUpdate = async (id) => {
    const response = await axios.delete(
      `https://api.rendernow.co/updates/${id}`
    );

    fetchUpdates();
  };

  const handleModalSubmit = async () => {
    try {
      const { title, description } = modalData;
      if (focusedUpdates) {
        await axios.patch("https://api.rendernow.co/updates", {
          updateId: focusedUpdates,
          title,
          description,
        });
      } else {
        await axios.post("https://api.rendernow.co/updates", {
          title,
          description,
        });
      }
    } catch (e) {
      alert("Failed");
    } finally {
      fetchUpdates();
      handleClose();
    }
  };

  useEffect(() => {
    fetchUpdates();
  }, []);

  const [youtubelinkwarn, setYoutubelinkwarn] = useState(false);
  const response = useSelector((state) => state.adminReducer.dashboard_message);
  const dispatch = useDispatch();

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let youtubelinks;
    if (data.youtube) {
      youtubelinks = youtube_parser(data.youtube);
      if (!youtubelinks) {
        setYoutubelinkwarn(true);
        return;
      }
    }
    dashboardpageapi(data, youtubelinks, dispatch);
    setData({
      welcome: "",
      welcome_text: "",
      youtube: "",
    });
  };

  return (
    <div
      className="d-flex flex-column w-100 h-100 bannerdec2"
      style={{
        borderBottom: "0.2rem solid rgb(25, 118, 210)",
        borderRadius: "8px",
      }}
    >
      {response === "show" && (
        <div
          className="alert alert-success mt-2 text-center font-weight-bold"
          role="alert"
        >
          Changes are successfully applied
        </div>
      )}

      {response === "no_show" && (
        <div className="alert alert-danger" role="alert">
          Some Error occured
        </div>
      )}
      <span className="text-right mt-3">
        <button
          type="submit"
          style={{ backgroundColor: "#1565c0", color: "white" }}
          className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </span>
      <section className="container-fluid w-100 overflow-auto">
        <section className="row my-3">
          <header
            className="col-lg col-12 text-left h5 bannerfont"
            style={{ fontWeight: 600 }}
          >
            Dashboard Content
          </header>
          <form className="col d-flex flex-column justify-content-left align-items-left col shadow-md card my-1">
            <label htmlFor="welcome" className="text-left mt-2">
              Welcome
            </label>
            <input
              type="text"
              name="welcome"
              id="welcome"
              value={data.welcome}
              onChange={(e) =>
                setData(() => ({ ...data, welcome: e.target.value }))
              }
            />
            <label htmlFor="video1" className="text-left mt-2">
              Welcome text
            </label>
            <input
              type="text"
              name="youtubes"
              id="video1"
              value={data.welcome_text}
              onChange={(e) =>
                setData(() => ({ ...data, welcome_text: e.target.value }))
              }
            />
            <label htmlFor="video2" className="text-left mt-2">
              Youtube video link
            </label>
            <input
              type="text"
              name="youtubes"
              id="video2"
              className={`mb-3 ${youtubelinkwarn && "border-danger"}`}
              value={data.youtube}
              onChange={(e) => {
                setYoutubelinkwarn(false);
                setData(() => ({ ...data, youtube: e.target.value }));
              }}
            />
          </form>
        </section>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
              }}
            >
              <label htmlFor="updateTitle" className="text-left mt-2">
                Title
              </label>
              <input
                type="text"
                name="youtubes"
                id="updateTitle"
                className={`mb-3 p-2`}
                style={{
                  border: "none",
                  outline: "1px solid #D9DDDC",
                  borderRadius: 5,
                }}
                value={modalData.title}
                onChange={(e) => {
                  setModalData((current) => ({
                    ...current,
                    title: e.target.value,
                  }));
                }}
                placeholder="Enter title"
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="updateTitle" className="text-left mt-2">
                Description
              </label>
              <ReactQuill
                theme="snow"
                value={modalData.description}
                onChange={(description) => {
                  setModalData((current) => ({
                    ...current,
                    description,
                  }));
                }}
                placeholder="Enter description"
              />
            </div>

            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "flex-end",
                marginTop: 15,
              }}
            >
              <button
                type="submit"
                style={{ backgroundColor: "#1565c0", color: "white" }}
                className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{ backgroundColor: "#1565c0", color: "white" }}
                className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
                onClick={handleModalSubmit}
              >
                Submit
              </button>
            </div>
          </Box>
        </Modal>

        <section className="my-3">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ opacity: 0 }}>
              <button
                type="submit"
                style={{ backgroundColor: "#1565c0", color: "white" }}
                className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
                onClick={() => {}}
              >
                Add
              </button>
            </div>
            <header
              className="col-lg col-12 text-center h5 bannerfont my-4"
              style={{ fontWeight: 600 }}
            >
              Recent Updates & News
            </header>

            <button
              type="submit"
              style={{ backgroundColor: "#1565c0", color: "white" }}
              className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
              onClick={handleOpen}
            >
              Add
            </button>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {!updatesData.length && (
              <div style={{ textAlign: "center", textTransform: "uppercase" }}>
                No updates
              </div>
            )}
            {updatesData.map((item) => (
              <div
                className="container"
                style={{
                  padding: 10,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#D9DDDC",
                  gap: 30,
                }}
              >
                <div style={{ flexDirection: "column" }}>
                  <div className="font-weight-bold mb-2">{item.title}</div>
                  <div
                    className="dashboard--news-description"
                    dangerouslySetInnerHTML={{
                      __html: makeLinksClickable(item.description),
                    }}
                    style={{ fontWeight: 400 }}
                  ></div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  <button
                    type="submit"
                    style={{ backgroundColor: "#1565c0", color: "white" }}
                    className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
                    onClick={() => {
                      setFocusedUpdates(item._id);
                      handleOpen();
                    }}
                  >
                    Edit
                  </button>

                  <button
                    type="submit"
                    style={{ backgroundColor: "#1565c0", color: "white" }}
                    className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
                    onClick={() => deleteUpdate(item._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
    </div>
  );
};

export default Dashboard;
